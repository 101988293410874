import { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Experience from './components/Experience';
import Hero from './components/Hero';
import { education, experiences, skills } from './shared/data';
import AboutMe from './components/AboutMe';
import ContactSection from './components/ContactSection';
import Education from './components/Education';
import Skills from './components/Skills';

export default function Portfolio() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Check local storage first
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme === 'dark';
    }
    // If no saved preference, check device preference
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  useEffect(() => {
    // Update class on document element
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    // Save preference to local storage
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 transition-colors duration-200">
      <Navbar toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />

      {/* Hero Section */}
      <Hero
        title="Bugra Ozdilek - Software Engineer"
        subtitle="Dynamic software engineer specializing in frontend development with expertise in Angular, React.js, NgRx, TypeScript, and Node.js"
      />

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* About Section */}
        <div id="about">
          <AboutMe />
        </div>

        {/* Experience Section */}
        <Experience experiences={experiences} />

        {/* Skills Section */}
        <div id="skills">
          <Skills skills={skills} />
        </div>

        {/* Projects Section */}
        {/* <div id="projects">
          <Card title="Featured Projects" >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {projects.map((project, index) => (
                <ProjectCard key={index} {...project} />
              ))}
            </div>
          </Card>
        </div> */}

        {/* Education Section */}
        <div id="education">
          <Education education={education} />
        </div>

        {/* Contact Section */}
        <div id="contact">
          <ContactSection />
        </div>
      </div>
    </div>
  );
}