export const skills = {
  frontend: ['Angular', 'React', 'Next.js', 'TypeScript', 'JavaScript', 'RxJS', 'NgRx', 'Ionic Framework', 'HTML', 'CSS', 'SCSS', 'Tailwind CSS'],
  backend: ['Node.js', 'Express.js', 'Python', 'MongoDB', 'Loopback4'],
  other: ['Git', 'Web Development', 'Data Science', 'Machine Learning', 'Solidity', 'p5.js', 'C#', 'Unity']
};

export const projects = [
  {
    title: "Telecom Customer Portal",
    description: "Led the development of a sophisticated customer management portal for Bouygues Telecom, serving over 1 million users.",
    technologies: ["Angular", "NgRx", "TypeScript", "Material UI"],
    link: "#"
  },
  {
    title: "E-Commerce Analytics Dashboard",
    description: "Designed and implemented a real-time analytics dashboard, improving decision-making efficiency by 40%.",
    technologies: ["React", "D3.js", "Node.js", "MongoDB"],
    link: "#"
  },
  {
    title: "Healthcare Appointment System",
    description: "Built a streamlined appointment booking system, reducing scheduling conflicts by 60%.",
    technologies: ["Angular", "Python", "PostgreSQL", "Docker"],
    link: "#"
  }
];

export const experiences = [
  {
    title: "Senior Specialist Software Developer",
    company: "ETIYA",
    period: "Aug 2024 – Current",
    responsibilities: [
      "Implemented robust state management solutions using NgXs",
      "Collaborated with software architects and analysts to translate conceptual designs into features",
      "Ensured pixel-perfect development, adhering to strict design specifications",
      "Proactively identified, diagnosed, and resolved critical bugs",
      "Consistently delivered high-quality code within tight deadlines"
    ]
  },
  {
    title: "Frontend Developer & Team Lead",
    company: "Rappider",
    period: "Dec 2020 – Feb 2024",
    responsibilities: [
      "Contributed to critical decision-making and project architecture development",
      "Managed integration, sprint planning, and training of a high-performing software engineering team",
      "Developed complex CRUD pages using Angular and implemented state management with NgRx and RxJs",
      "Created over 25 Angular components for project use",
      "Facilitated collaboration between frontend and backend teams for API development and consumption",
      "Designed and implemented a comment and notification system using Angular components"
    ]
  }
];

export const education = [
  {
    degree: "Bachelor of Science in Software Engineering",
    institution: "Bahcesehir University",
    period: "2019 - 2023",
    details: [
      "Developed capstone project comparing edge detection algorithms for skin lesion segmentation",
      "Demonstrated proficiency in data science, algorithms, advanced networks, web development, and software architecture"
    ]
  },
  {
    degree: "Engineering preparation and Italian language school",
    institution: "Scuola Leonardo da Vinci",
    period: "2017 - 2019",
    details: [
      "Took Engineering and Architecture preparation course",
      "Studied Italian language to C1 level"
    ]
  }
];