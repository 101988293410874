import React from 'react';
import { motion } from 'framer-motion';
import { Code, Server, Hammer } from 'lucide-react';
import Card from './Card';
import Badge from './Badge';

interface SkillsProps {
  skills: {
    [key: string]: string[];
  };
}

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3
    }
  }
};

const getIconForCategory = (category: string) => {
  switch (category.toLowerCase()) {
    case 'frontend':
      return <Code className="w-6 h-6 text-blue-500 mr-2" />;
    case 'backend':
      return <Server className="w-6 h-6 text-green-500 mr-2" />;
    default:
      return <Hammer className="w-6 h-6 text-purple-500 mr-2" />;
  }
};

const Skills: React.FC<SkillsProps> = ({ skills }) => {
  return (
    <Card title="Skills">
      <motion.div
        className="space-y-6"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {Object.entries(skills).map(([category, skillList]) => (
          <motion.div
            key={category}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6"
            variants={itemVariants}
          >
            <div className="flex items-center mb-4">
              {getIconForCategory(category)}
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                {category.charAt(0).toUpperCase() + category.slice(1)} Development
              </h3>
            </div>
            <motion.div
              className="flex flex-wrap gap-2"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
            >
              {skillList.map((skill, index) => (
                <motion.div key={index} variants={itemVariants}>
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}>
                    <Badge>{skill}</Badge>
                  </motion.div>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        ))}
      </motion.div>
    </Card>
  );
};

export default Skills;