import React from 'react';
import { motion } from 'framer-motion';
import { Code, Users, Lightbulb, Download } from 'lucide-react';
import Card from './Card';

interface FeatureCardProps {
  icon: React.ElementType;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon: Icon, title, description }) => {
  return (
    <motion.div
      className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
      whileHover={{ y: -5 }}
    >
      <Icon className="w-12 h-12 mb-4 text-blue-500" />
      <h3 className="text-xl font-semibold mb-2 dark:text-white">{title}</h3>
      <p className="text-gray-600 dark:text-gray-300">{description}</p>
    </motion.div>
  );
};

const AboutMe: React.FC = () => {
  return (
    <div id="about">
      <Card title="About Me">
        <div className="space-y-6 text-gray-700 dark:text-gray-300">
          <p className="text-lg leading-relaxed">
            As a dynamic software engineer with over 3 years of experience, I specialize in crafting robust, user-centric web applications. My expertise spans both frontend and backend development, with a particular focus on Angular and React ecosystems. I'm passionate about creating seamless digital experiences that not only meet but exceed user expectations.
          </p>
          <p className="text-lg leading-relaxed">
            My journey in software engineering has been marked by continuous learning and adaptation to emerging technologies. From my academic background at Bahçeşehir University to my professional experiences at ETIYA and Rappider, I've consistently pushed the boundaries of what's possible in web development.
          </p>
          <motion.div
            className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <FeatureCard
              icon={Code}
              title="Technical Excellence"
              description="Crafting clean, efficient code and architecting scalable solutions that stand the test of time and growth."
            />
            <FeatureCard
              icon={Users}
              title="Team Collaboration"
              description="Leading teams with empathy and mentoring junior developers to foster a culture of continuous improvement."
            />
            <FeatureCard
              icon={Lightbulb}
              title="Innovation Focus"
              description="Constantly exploring and implementing emerging technologies to stay at the forefront of web development."
            />
          </motion.div>
          <motion.div
            className="mt-8 flex justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <a
              href="/Bugra-Ozdilek-Software-Engineer-CV-EN.pdf"
              download="BugraOzdilek_Resume.pdf"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300"
            >
              Download Resume <Download className="ml-2 h-5 w-5" />
            </a>
          </motion.div>
        </div>
      </Card>
    </div>
  );
};

export default AboutMe;