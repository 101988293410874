import React from 'react';
import { motion } from 'framer-motion';
import { GraduationCap, Calendar } from 'lucide-react';
import Card from './Card';

interface EducationItem {
  degree: string;
  institution: string;
  period: string;
  details: string[];
}

interface EducationProps {
  education: EducationItem[];
}

const EducationCard: React.FC<EducationItem> = ({ degree, institution, period, details }) => (
  <motion.div
    className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-8 last:mb-0"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="flex items-center mb-4">
      <GraduationCap className="w-6 h-6 text-blue-500 mr-2" />
      <h3 className="text-xl font-semibold text-gray-900 dark:text-white">{degree}</h3>
    </div>
    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4">
      <h4 className="text-lg text-blue-600 dark:text-blue-400 mb-2 sm:mb-0">{institution}</h4>
      <div className="flex items-center text-gray-500 dark:text-gray-400">
        <Calendar className="w-4 h-4 mr-1" />
        <p>{period}</p>
      </div>
    </div>
    {details.length > 0 && (
      <ul className="space-y-2">
        {details.map((detail, idx) => (
          <motion.li
            key={idx}
            className="flex items-start"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: idx * 0.1 }}
          >
            <span className="text-blue-500 mr-2">•</span>
            <span className="text-gray-700 dark:text-gray-300">{detail}</span>
          </motion.li>
        ))}
      </ul>
    )}
  </motion.div>
);

const Education: React.FC<EducationProps> = ({ education }) => {
  return (
    <div id="education">
      <Card title="Education">
        <div className="space-y-8">
          {education.map((edu, index) => (
            <EducationCard key={index} {...edu} />
          ))}
        </div>
      </Card>
    </div>
  );
};

export default Education;