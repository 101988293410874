import React from 'react';
import { motion } from 'framer-motion';
import { Briefcase, Calendar } from 'lucide-react';
import Card from './Card';

interface ExperienceItem {
  title: string;
  company: string;
  period: string;
  responsibilities: string[];
}

interface ExperienceProps {
  experiences: ExperienceItem[];
}

const ExperienceCard: React.FC<ExperienceItem> = ({ title, company, period, responsibilities }) => (
  <motion.div
    className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-8 last:mb-0"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="flex items-center mb-4">
      <Briefcase className="w-6 h-6 text-blue-500 mr-2" />
      <h3 className="text-xl font-semibold text-gray-900 dark:text-white">{title}</h3>
    </div>
    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4">
      <h4 className="text-lg text-blue-600 dark:text-blue-400 mb-2 sm:mb-0">{company}</h4>
      <div className="flex items-center text-gray-500 dark:text-gray-400">
        <Calendar className="w-4 h-4 mr-1" />
        <p>{period}</p>
      </div>
    </div>
    <ul className="space-y-2">
      {responsibilities.map((responsibility, index) => (
        <motion.li
          key={index}
          className="flex items-start"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, delay: index * 0.1 }}
        >
          <span className="text-blue-500 mr-2">•</span>
          <span className="text-gray-700 dark:text-gray-300">{responsibility}</span>
        </motion.li>
      ))}
    </ul>
  </motion.div>
);

const Experience: React.FC<ExperienceProps> = ({ experiences }) => {
  return (
    <div id="experience">
      <Card title="Professional Experience">
        <div className="space-y-8">
          {experiences.map((exp, index) => (
            <ExperienceCard key={index} {...exp} />
          ))}
        </div>
      </Card>
    </div>
  );
};

export default Experience;