import React from 'react';
import { motion } from 'framer-motion';
import { Mail, Linkedin, Github, MapPin, ExternalLink } from 'lucide-react';
import Card from './Card';

const ContactItem: React.FC<{ icon: React.ReactNode; label: string; value: string; href: string }> = ({ icon, label, value, href }) => (
  <motion.a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <div className="mr-4 text-blue-500">{icon}</div>
    <div>
      <p className="text-sm text-gray-500 dark:text-gray-400">{label}</p>
      <p className="text-lg font-semibold text-gray-800 dark:text-white">{value}</p>
    </div>
    <ExternalLink className="ml-auto text-gray-400" size={18} />
  </motion.a>
);

const ContactSection: React.FC = () => {
  return (
    <div id="contact">
      <Card title="Get in Touch">
        <div className="space-y-6">
          <p className="text-lg text-gray-700 dark:text-gray-300 mb-8">
            I'm always open to discussing new projects, creative ideas, or opportunities to be part of your visions. Feel free to reach out through any of the following channels:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <ContactItem
              icon={<Mail size={24} />}
              label="Email"
              value="bugraozdilek@gmail.com"
              href="mailto:bugraozdilek@gmail.com"
            />
            <ContactItem
              icon={<Linkedin size={24} />}
              label="LinkedIn"
              value="Bugra Ozdilek"
              href="https://www.linkedin.com/in/bugra-ozdilek123/"
            />
            <ContactItem
              icon={<Github size={24} />}
              label="GitHub"
              value="bugraOzd"
              href="https://github.com/bugraOzd"
            />
            <ContactItem
              icon={<MapPin size={24} />}
              label="Location"
              value="Turkiye"
              href="https://www.google.com/maps/place/Turkey"
            />
          </div>
          <div className="mt-8 text-center">
            <p className="text-gray-600 dark:text-gray-400">
              Prefer a more traditional approach? Feel free to schedule a call or video chat!
            </p>
            <motion.a
              href="https://calendly.com/bugraozdilek"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block mt-4 px-6 py-3 bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-700 transition-colors duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Schedule a Meeting
            </motion.a>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ContactSection;